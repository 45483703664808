import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import HeaderSection from '../components/HeaderSection/HeaderSection';

const NotFoundPage = () => (
  <Layout>
    <SEO title="Our Mission" />
    <HeaderSection title="404 - Not found" subtitle="Page could not be found." />
  </Layout>
);

export default NotFoundPage;
